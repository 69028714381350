<template>
    <div>
        <v-btn v-if="!isIcon" color="blue" :loading="loading" dark block @click="exportToExcel">{{ $t('Export Excel') }}
        </v-btn>
        <v-icon v-if="isIcon" :loading="loading" @click="exportToExcel" color="green">mdi-microsoft-excel</v-icon>
    </div>
</template>
<script>
import axios from 'axios';
import { saveAs } from 'file-saver';
export default {
    props: {
        isIcon: {
            type: Boolean,
            default: false,
        },
        endPoint: {
            type: String,
        },
        id: {
            type: Number,
        },
        params: {
            type: Object,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        async exportToExcel() {
            try {
                this.loading = true;
                let url = "";
                if (this.isIcon) url = `${this.endPoint}/${this.id}`; else url = `${this.endPoint}`;
                const excel = await axios.get(url, {
                    params: { ...this.params, },
                    responseType: 'blob'
                });
                saveAs(excel.data)
            } catch (err) {
                console.log('err', err);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
<style></style>